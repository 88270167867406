// custom styles for button
$button_group_spacing: 12px;
$button_group_spacing_sm: $button_group_spacing / 2;
$button_group_spacing_lg: $button_group_spacing * 2;
.button-group {
  display: flex;
  width: 100%;

  &.button-group-left {
    justify-content: flex-start;
    button {
      margin: 0 $button_group_spacing_lg 0 0;
    }

    &.button-group-spacing-sm {
      button {
        margin: 0 $button_group_spacing 0 0;
      }
    }
  }

  &.button-group-center {
    justify-content: center;
    button {
      margin: 0 $button_group_spacing;
    }

    &.button-group-spacing-sm {
      button {
        margin: 0 $button_group_spacing_sm;
      }
    }
  }

  &.button-group-right {
    justify-content: flex-end;
    button {
      margin: 0 0 0 $button_group_spacing_lg;
    }
    &.button-group-spacing-sm {
      button {
        margin: 0 0 0 $button_group_spacing;
      }
    }
  }

  &.button-group-icon-text {
    em {
      margin-right: 12px;
    }
  }
  &.full-button {
    app-button {
      width: 100% !important;
      display: flex;
      button {
        min-width: 100% !important;
      }
    }
  }

  &.half-button {
    app-button {
      display: flex;
      flex: 1;
      width: calc(100% - 16px);
      &:first-child {
        margin-right: 8px;
      }
      &:last-child {
        margin-left: 8px;
      }
      button {
        width: 100%;
      }
    }
  }
}

.floating-button {
  .fixed {
    position: fixed;
    bottom: 0;
    z-index: $zIndex-floating-button;
  }
}
