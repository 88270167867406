//Mandatory asterix form
.form-group.required .control-label:after,
.asterisk::after,
.form-group .control-label.required::after {
  content: ' *';
  color: red;
  position: absolute;
  margin-left: 3px;
  top: -3px;
}

.control-label {
  font-weight: bold;
  position: relative;
}

//Form progress loader
.form-loader {
  height: 1px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.form-loader:before {
  display: block;
  position: absolute;
  content: '';
  left: -200px;
  width: 200px;
  height: 1px;
  background-color: #2980b9;
  animation: loading 2s linear infinite;
}

.form-idle {
  display: block;
  position: relative;
  height: 1px;
}

@keyframes loading {
  from {
    left: -200px;
    width: 30%;
  }

  50% {
    width: 30%;
  }

  70% {
    width: 70%;
  }

  80% {
    left: 50%;
  }

  95% {
    left: 120%;
  }

  to {
    left: 100%;
  }
}

//ng-bootstrap modal animation
@keyframes modal-b-fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.5;
  }
}

@keyframes modal-fade {
  from {
    top: -30px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.modal-backdrop {
  opacity: 0.5;
  animation: ease-in-out 0.3s modal-b-fade;
  transition: all 0.3s ease-in-out;
}

.modal {
  top: -30px;
  animation: ease-in-out 0.2s modal-fade;
  transition: all 0.3s ease-in-out;

  &.show {
    top: 0;
  }
}

.modal-open {
  overflow: visible;
}

//PRIMENG DATATABLE
// -- pagination bg color
body .ui-paginator .ui-paginator-pages .ui-paginator-page.ui-state-active {
  background-color: #9d9d9d;
}

// -- fix data cell layout
.ui-table table {
  table-layout: auto !important;
  vertical-align: text-top;
}

// replace pagination color manually since ngb-pagination doesnt use primary class
.page-link {
  border: 0px;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
  border-radius: 0.25rem;
}

//on focus
.form-control:focus {
  box-shadow: none;
}

//picklist hidden button source&target
.ui-picklist-source-controls {
  display: none !important;
}

.ui-picklist-target-controls {
  display: none !important;
}

// //CUSTOM CSS DOWN BELOW
// /* Insert Your Custom CSS Here */
.navbar-tabs-menu .vd_panel-menu .menu {
  width: 100%;
  border: 0;
  margin: 0;
}

.vd_panel-menu .menu:hover,
.vd_panel-menu .menu.active,
.vd_panel-menu .text-menu:hover,
.vd_panel-menu .text-menu.active {
  background-color: transparent;
  color: #1fae66;
}

.nav-left-medium .vd_navbar-left.vd_navbar-tabs-profile .navbar-menu .vd_menu,
.nav-right-medium
  .vd_navbar-right.vd_navbar-tabs-profile
  .navbar-menu
  .vd_menu {
  margin-top: 0;
}

header .vd_top-menu-wrapper {
  background-color: #fff;
  border-bottom: 1px solid #107caa;
}

.vd_mega-menu-wrapper
  .vd_mega-menu
  > .mega-ul
  > li.one-big-icon
  > .mega-link
  > .badge {
  right: inherit;
  top: inherit;
  bottom: 10px;
  left: 0px;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  padding: 0;
}

.nav-left-medium .vd_navbar-left,
.nav-right-medium .vd_navbar-right,
.nav-left-medium
  .vd_navbar-left.vd_navbar-tabs-profile
  .navbar-tabs-menu
  .menu-container,
.nav-right-medium
  .vd_navbar-right.vd_navbar-tabs-profile
  .navbar-tabs-menu
  .menu-container {
  width: 170px;
}

.nav-left-medium .content .vd_container {
  margin-left: 155px;
}

//aman
.nav-left-medium .vd_navbar-left .vd_menu > ul > li > a,
.nav-right-medium .vd_navbar-right .vd_menu > ul > li > a {
  line-height: 1.6;
}

.vd_navbar {
  background-color: #107caa;
}

.vd_nav-width {
  width: 170px;
}

.vd_container {
  margin-left: 175px;
}

.vd_panel-menu .menu {
  font-size: 20px;
}

.navbar-tabs-menu {
  background-color: rgba(0, 0, 0, 0.2);
}

.nav-left-medium .vd_navbar-left .navbar-tabs-menu .menu-container,
.nav-right-medium .vd_navbar-right .navbar-tabs-menu .menu-container,
.nav-left-small .vd_navbar-left .navbar-tabs-menu .menu-container,
.nav-right-small .vd_navbar-right .navbar-tabs-menu .menu-container {
  background: transparent;
}

header .vd_top-nav .vd_panel-header .logo a {
  margin-top: 12px;
  margin-left: -4px;
  width: 140px;
}

.vd_top-menu-wrapper h1 {
  font-weight: 700;
  font-size: 22px;
  color: #107caa;
  margin: 20px 0;
}

.vd_mega-menu-wrapper {
  width: auto;
  display: inline;
}

.app_desc {
  display: inline-block;
  margin: 11px 20px;
}

.app_desc h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 2px;
  color: #107caa;
}

.app_desc span {
  font-weight: 700;
  color: #555555;
}

.vd_mega-menu-content .child-menu .title {
  text-align: left;
}

.vd_mega-menu-wrapper .vd_mega-menu > .mega-ul > li.one-big-icon > .mega-link {
  line-height: 36px;
  font-size: 24px;
  color: #107caa;
  background: #fff;
  border: 1px solid #107caa;
  .badge {
    background: #29bbf9 !important;
  }
}

.vd_mega-menu-content {
  border-top: 3px solid #107caa;
}

.vd_mega-menu-content:before {
  border-color: transparent transparent #107caa;
}

.panel-bd-top {
  border-top: none;
}

.dot-green {
  font-size: 10px;
  top: -2px;
  position: relative;
  color: #6abd48;
  margin-right: 5px;
}

.dot-grey {
  font-size: 10px;
  top: -2px;
  position: relative;
  color: #818185;
  margin-left: 5px;
  margin-right: 5px;
}

.widget .vd_panel-menu .menu {
  display: inline-block;
  float: none;
}

.widget .vd_panel-menu select {
  padding: 2px 5px;
  position: relative;
  width: 105px;
}

.cost-wrapper {
  display: flex;
  align-items: center;
  border-right: 1px solid #ddd;
  padding: 17px 15px;
}

.cost-wrapper .cost-alloc {
  flex: 1;
}

.cost-wrapper .progress {
  flex: 4;
  margin: 0;
  height: 30px;
  border-radius: 30px;
}

.cost-wrapper .progress .progress-capex {
  background: linear-gradient(to right, #29b78a, #68bf41);
}

.cost-wrapper .progress .progress-opex {
  background: linear-gradient(to right, #3e7676, #3ac4ef);
}

.cost-wrapper .progress .progress-bar {
  font-size: 18px;
  line-height: 30px;
}

.cost-wrapper .progress .progress-bar span {
  width: 50%;
  display: inline-block;
  text-align: left;
  padding: 0 10px;
  vertical-align: middle;
}

.cost-wrapper .progress .progress-bar span.title {
  font-size: 12px;
}

.cost-wrapper .progress .progress-bar span:last-child {
  text-align: right;
}

.cost-alloc .menu-icon {
  font-size: 30px;
  float: left;
  border-radius: 20px;
  border: 1px solid #29bbf9;
  color: #29bbf9;
  height: 60px;
  width: 60px;
  text-align: center;
  line-height: 58px;
  margin-right: 10px;
}

.cost-alloc .menu-value {
  /* font-size: 20px; */
  font-weight: 700;
  top: 8px;
  position: relative;
}

.cost-alloc .menu-value small {
  font-weight: 400;
  margin-top: -5px;
  line-height: 1;
  display: block;
}

.vd_content-section .row {
  margin-bottom: 0;
}

.dropdown-wrapper,
.dropdown-choice {
  display: flex;
  align-items: center;
}

.dropdown-choice .ddown {
  margin-right: 10px;
  font-size: 12px;
  flex: 1;
}

.dropdown-choice .ddown select {
  padding: 2px 5px;
  font-size: 13px;
}

.dropdown-wrapper .refresh,
.viewall em {
  cursor: pointer;
  color: #29bbf9;
  border: 1px solid #29bbf9;
  width: 40px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 6px;
  font-size: 15px;
  margin-right: 15px;
  margin-left: 10px;
}

.viewall em {
  height: 40px;
  border-radius: 40px;
  line-height: 38px;
  margin: 0 8px 0 5px;
  display: block;
}

.viewall {
  cursor: pointer;
  text-align: center;
  font-size: 12px;
}

.dropdown-wrapper .refresh:hover,
.viewall:hover em {
  color: #fff;
  background: #29bbf9;
}

.donut {
  text-align: center;
  border-right: 1px solid #ddd;
}

.donut-desc {
  text-align: center;
  padding: 0 15px;
}

.donut-desc .donut-detail {
  padding-bottom: 10px;
}

.donut-desc .donut-detail:last-child {
  border-top: 1px solid #ddd;
  padding-top: 10px;
  margin-top: 5px;
}

.donut-desc h4 {
  font-size: 14px;
}

.donut-desc span {
  font-size: 30px;
  font-weight: 700;
  padding: 10px;
  display: inline-block;
}

.donut-desc span.blue {
  color: #3ac0e9;
}

.donut-desc span.green {
  border-left: 1px solid #ddd;
  color: #66bf43;
}

body.nav-left-small .menu-badge {
  display: none;
}

.rk-alert {
  padding: 15px 10px;
  border-right: 1px solid #ddd;
  height: 104px;
}

.rk-alert .menu-icon {
  border: none;
  font-size: 60px;
  width: 75px;
  height: 75px;
  color: #fff;
  background: linear-gradient(to right, #dc272a, #f2592c);
  border-radius: 75px;
  line-height: 75px;
}

.rk-alert .menu-value {
  border: 1px solid #f2592c;
  border-right-width: 5px;
  border-radius: 0 60px 60px 0;
  display: inline-block;
  padding: 5px 25px 5px 35px;
  margin-left: -30px;
  color: #f2592c;
}

.rk-carousel-wrapper {
  display: flex;
  align-items: center;
  border-right: 1px solid #ddd;
  padding: 0 10px;
}

.rk-carousel .table > tbody > tr > td {
  padding: 4px 10px;
}

.rk-carousel .header-table {
  margin-bottom: -8px;
}

.rk-carousel .table {
  margin: 0;
}

.rk-carousel .header-table span {
  background: #ddd;
}

.rk-carousel .header-table {
  em {
    background: linear-gradient(to right, #dc272a, #f2592c);
    color: #fff;
    width: 25px;
    height: 25px;
    font-size: 16px;
    border-radius: 25px;
    text-align: center;
    line-height: 25px;
    z-index: 9;
    position: relative;
  }
  span {
    background: #f1572c;
    color: #fff;
    padding: 1px 10px 1px 13px;
    border-radius: 0 10px 0 0;
    margin-left: -12px;
  }

  &.yellow span {
    background: #eedc04;
    padding-right: 25px;
    margin: 0;
  }
  &.grey span {
    background: #ccc;
    padding-right: 25px;
    margin: 0;
  }
}

.rk-carousel-wrapper .rk-carousel {
  width: 100%;
  overflow: hidden;
}

.rk-carousel .table > tbody > tr > td:last-child {
  text-align: right;
}

.rk-carousel .table > tbody > tr:first-child > td {
  padding-top: 10px;
}

.swiper-wrapper {
  padding: 0 40px;
}

.swiper-slide {
  width: 105px !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-size: 15px !important;
  width: 15px;
}

// primeNG hide upload content
.ui-fileupload-row {
  display: none !important;
}

// primeNG end
hr {
  display: block;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-left: auto;
  margin-right: auto;
  border-style: inset;
  border-width: 1px;
}

/*=========  Approval Module Custom CSS =========*/

.approval-list {
  position: relative;
  padding: 10px 40px 0;
  overflow-x: auto;
}

.approval-list ul {
  white-space: nowrap;
  overflow: auto;
  list-style: none;
}

.approval-list ul li {
  vertical-align: top;
  display: inline-block;
}

.approval-icon-text {
  margin-top: 5px;
  font-size: 17px;
}

.approval-arrow-right {
  vertical-align: top;
  padding: 15px 10px;
}

/*=========   Modal Custom CSS =========*/

.modal-custom .modal-dialog {
  max-width: 900px;
}

@media only screen and (min-width: 992px) {
  .modal-custom .modal-content {
    position: absolute;
    top: -5px;
  }
}

.modal-tree .modal-dialog {
  max-width: 1000px;
}

@media only screen and (min-width: 992px) {
  .modal-tree .modal-content {
    position: absolute;
    top: -5px;
  }
}

@media only screen and (max-width: 992px) {
  .modal-tree .modal-content {
    white-space: nowrap;
    overflow: auto;
  }
}

.modal-workflow-conditional .modal-dialog {
  max-width: 500px;
}

@media only screen and (min-width: 992px) {
  .modal-workflow-conditional .modal-content {
    position: absolute;
    top: -5px;
  }
}

// custom class defined for cursor
.cursor-pointer {
  cursor: pointer !important;
}

.cursor-progress {
  cursor: progress;
}

.cursor-default {
  cursor: default !important;
}

.cursor-help {
  cursor: help;
}

.cursor-copy {
  cursor: copy;
}

// outline-none
.outline-none {
  outline: none !important;
}

// box-shadow
.box-shadow-none {
  box-shadow: none !important;
}

// ng-prime tab-view custom style
// body .ui-tabview .ui-tabview-panels {
//     background-color: transparent;
//     padding: 0;
//     border: 1px solid transparent;
// }

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active {
  background: #00accc !important;
  border: #00accc !important;
}

body .ui-tabview.ui-tabview-top .ui-tabview-nav li.ui-state-active:hover,
body .ui-tabview.ui-tabview-bottom .ui-tabview-nav li.ui-state-active:hover,
body .ui-tabview.ui-tabview-left .ui-tabview-nav li.ui-state-active:hover,
body .ui-tabview.ui-tabview-right .ui-tabview-nav li.ui-state-active:hover {
  background: #008299 !important;
  border: #008299 !important;
}

// line separator
.clear {
  width: inherit;
  border-bottom: 1px solid #ced4da;
}

// tooltip absolute right
div.app-textfield i.app-textfield-tooltip {
  position: absolute;
  right: -1.5em;
  top: 0;
  z-index: 1;
  transform: translate(0, 25%);
}

div.app-textfield i.app-textfield-tooltip:after {
  content: '\f05a';
  font-family: 'Font Awesome 5 Free', monospace;
  font-style: normal;
  font-weight: bold;
}

h1.separator,
h2.separator,
h3.separator,
h4.separator,
h5.separator,
h6.separator {
  border-bottom: 0.05rem solid #ccc;
  display: block;
  padding-bottom: 0.6rem;
  margin-bottom: 2rem;
}

#vertical-menu {
  .card a {
    display: flex;

    em {
      padding-top: 0.4rem;
    }

    span {
      padding-right: 2rem;
    }

    strong {
      position: absolute;
      right: 0;
    }
  }
}

// overide popup checkbox color
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: var(--primary);
}

.white-space-normal {
  white-space: normal !important;
  word-break: break-word;
}

// Floating button fixed element
div.floating-button {
  div.floating-button-wrapper {
    &.fixed {
      display: flex;
      justify-content: center;
      div.floating-button-content {
        width: 100%;
        padding: 32px 0;
        background: #fff;
        border-top-left-radius: 0.2rem;
        border-top-right-radius: 0.2rem;
      }
    }
  }
}

// p-picklist button colors
div.ui-picklist-buttons .ui-picklist-buttons-cell button {
  background-color: var(--primary);
  border-color: var(--primary);
  &:enabled:hover {
    background-color: var(--primary-dark);
  }
}

div.ui-picklist-filter-container .ui-picklist-filter-icon.pi.pi-search {
  color: var(--primary);
}

.custom-radio
  .custom-control-input:not(:disabled):not(:checked):not(:indeterminate)
  ~ .custom-control-label::before,
.custom-checkbox
  .custom-control-input:not(:disabled):not(:checked):not(:indeterminate)
  ~ .custom-control-label::before {
  background-color: #ffffff;
  border: 1px solid#ced4da !important;
}

div.alert-wrapper {
  div.alert-wrapper-content {
    position: relative;
  }
}

// toast

div.promise-toast-wrapper {
  position: fixed;
  z-index: 9999;
  transition: all;
}

div.promise-toast-wrapper.top.right,
div.promise-toast-wrapper.top.left,
div.promise-toast-wrapper.top.center {
  top: 4rem;
}

div.promise-toast-wrapper.bottom.right,
div.promise-toast-wrapper.bottom.left,
div.promise-toast-wrapper.bottom.center {
  bottom: 2rem;
}

div.promise-toast-wrapper.top.center {
  left: calc(50% - 182.5px);
}

div.promise-toast-wrapper.top.right div.promise-toast,
div.promise-toast-wrapper.bottom.right div.promise-toast {
  right: calc(-1rem + -365px);
}

div.promise-toast-wrapper.top.right div.promise-toast.show,
div.promise-toast-wrapper.bottom.right div.promise-toast.show {
  right: 0;
  animation: show_toast_right 1s ease forwards;
}

@keyframes show_toast_right {
  0% {
    transform: translateX(100%);
  }
  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(0%);
  }

  100% {
    transform: translateX(-10px);
  }
}

div.promise-toast-wrapper.top.right div.promise-toast.hide,
div.promise-toast-wrapper.bottom.right div.promise-toast.hide {
  animation: hide_toast_right 1s ease forwards;
}

@keyframes hide_toast_right {
  0% {
    transform: translateX(-10px);
  }
  40% {
    transform: translateX(0%);
  }

  80% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(calc(100% + 1rem));
  }
}

div.promise-toast-wrapper.bottom.right,
div.promise-toast-wrapper.top.right {
  right: 1rem;
}

div.promise-toast-wrapper.bottom.center {
  left: calc(50% - 182.5px);
}

div.promise-toast-wrapper.top.left div.promise-toast,
div.promise-toast-wrapper.bottom.left div.promise-toast {
  left: calc(-1rem + -365px);
}

div.promise-toast-wrapper.top.left div.promise-toast.show,
div.promise-toast-wrapper.bottom.left div.promise-toast.show {
  left: 0;
  animation: show_toast_left 1s ease forwards;
}

@keyframes show_toast_left {
  0% {
    transform: translateX(-100%);
  }
  40% {
    transform: translateX(10px);
  }

  80% {
    transform: translateX(-10%);
  }

  100% {
    transform: translateX(0);
  }
}

div.promise-toast-wrapper.top.left div.promise-toast.hide,
div.promise-toast-wrapper.bottom.left div.promise-toast.hide {
  animation: hide_toast_left 1s ease forwards;
}

@keyframes hide_toast_left {
  0% {
    transform: translateX(0);
  }
  40% {
    transform: translateX(-10%);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    transform: translateX(-100%);
  }
}

div.promise-toast-wrapper.bottom.left,
div.promise-toast-wrapper.top.left {
  left: 1rem;
}

div.promise-toast-wrapper div.promise-toast {
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: relative;
  box-shadow: 0px 0px 7px 1px rgb(0 0 0 / 10%);
  width: 365px;
  margin-bottom: 1rem;
  border-radius: 0.2rem;
  transition: 0.2s ease;
}

div.promise-toast-wrapper div.promise-toast div.toast-icon {
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

div.promise-toast-wrapper div.promise-toast div.toast-icon em {
  text-align: center;
  line-height: 32px;
  margin: 0 1rem;
}

div.promise-toast-wrapper div.promise-toast div.toast-content {
  padding: 1rem 3.2rem 1.2rem 1rem;
  flex: 1;
}

div.promise-toast-wrapper div.promise-toast div.toast-content p.toast-text {
  color: #565656;
  margin: 0;
}

div.promise-toast-wrapper div.promise-toast div.toast-action {
  font-size: 1.4em;
  width: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

div.promise-toast-wrapper div.promise-toast div.toast-action:hover {
  color: red;
  background-color: #f7f7f7 !important;
}

/* colorize */
div.promise-toast-wrapper div.promise-toast-info {
  background-color: #f6faff;
}

div.promise-toast-wrapper div.promise-toast-info div.toast-icon em {
  color: var(--primary);
}

div.promise-toast-wrapper div.promise-toast-success {
  background-color: #f5fff6;
}

div.promise-toast-wrapper div.promise-toast-success div.toast-icon em {
  color: var(--success);
}

div.promise-toast-wrapper div.promise-toast-warning {
  background-color: #fffcf0;
}

div.promise-toast-wrapper div.promise-toast-warning div.toast-icon em {
  color: var(--warning);
}

div.promise-toast-wrapper div.promise-toast-danger {
  background-color: #fbf8f8;
}

div.promise-toast-wrapper div.promise-toast-danger div.toast-icon em {
  color: var(--danger);
}

div.promise-toast-wrapper div.promise-toast div.toast-indicator {
  // background: #f3f3f3;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 4px;
}

div.promise-toast-wrapper
  div.promise-toast
  div.toast-indicator
  div.indicator-progress {
  background-color: #bcbbb9;
  width: 100%;
  display: block;
  height: 4px;
  animation: toastIndicator 5s normal forwards infinite;
}

@keyframes toastIndicator {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

div.input-group.input-group-search {
  max-width: 250px;
  position: relative;
  div.input-group-prepend {
    span.input-group-text {
      background-color: transparent;
      border-right-color: transparent;
    }
  }
  input.form-control {
    border-left-color: transparent;
  }
  em.btn-delete {
    position: absolute;
    right: 8px;
    top: 25%;
    cursor: pointer;
    z-index: 3;
  }
}
