[class^='pvc'],
[class^='pvs'],
[class^='pvf'],
[class*=' pv-'] {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 40px;
  height: 40px;
}

.pv-1x {
  width: 64px;
  height: 64px;
}

.pv-2x {
  width: 80px;
  height: 80px;
}

.pvc {
  &.pv-ellipsis-v {
    background-image: url('/assets/svg/pv-ellipsis-v.svg');
  }

  &.pv-file-download {
    background-image: url('/assets/svg/pv-file-downlod.svg');
  }

  &.pv-shopping-cart {
    background-image: url('/assets/svg/pv-shopping-cart.svg');
  }

  &.pv-envelope {
    background-image: url('/assets/svg/pv-envelope.svg');
  }

  &.pv-cloud-download {
    background-image: url('/assets/svg/pv-cloud-download.svg');
  }

  &.pv-trophy {
    background-image: url('/assets/svg/pv-trophy.svg');
  }

  &.pv-confetti {
    background-image: url('/assets/svg/pv-confetti.svg');
  }

  &.pv-check-circle {
    background-image: url('/assets/svg/pv-check-circle.svg');
  }
  &.pv-exclamation-circle {
    background-image: url('/assets/svg/pv-exclamation-circle.svg');
  }
  &.pv-image {
    background-image: url('/assets/svg/pv-image.svg');
  }
  &.pv-file {
    background-image: url('/assets/svg/pv-file.svg');
  }
  &.pv-exclamation-triangle {
    background-image: url('/assets/svg/pv-exclamation-triangle.svg');
  }
  &.pv-exclamation-triangle-alert {
    background-image: url('/assets/svg/pv-exclamation-triangle-alert.svg');
  }
  &.pv-search {
    background-image: url('/assets/svg/pv-search.svg');
  }
  &.pv-wallet {
    background-image: url('/assets/svg/pv-wallet.svg');
  }
  &.pv-calendar-alt {
    background-image: url('/assets/svg/pv-calendar-alt.svg');
  }
  &.pv-file-list {
    background-image: url('/assets/svg/pv-file-list.svg');
  }
  &.pv-comment-dots {
    background-image: url('/assets/svg/pv-comment-dots.svg');
  }
  &.pv-binary-tree {
    background-image: url('/assets/svg/pv-binary-tree.svg');
  }
  &.pv-user-circle {
    background-image: url('/assets/svg/pv-user-circle.svg');
  }
  &.pv-sattelite-antenna {
    background-image: url('/assets/svg/pv-sattelite-antenna.svg');
  }
  &.pv-sandglass {
    background-image: url('/assets/svg/pv-sandglass.svg');
  }
  &.pv-user-friends {
    background-image: url('/assets/svg/pv-user-friends.svg');
  }
  &.pv-link {
    background-image: url('/assets/svg/pv-link.svg');
  }
  &.pv-lightbulb {
    background-image: url('/assets/svg/pv-lightbulb.svg');
  }
  &.pv-envelope-reminder {
    background-image: url('/assets/svg/pv-envelope-reminder.svg');
  }
  &.pv-files-alt-circle-check {
    background-image: url('/assets/svg/pv-files-alt-circle-check.svg');
  }
  &.pv-lock {
    background-image: url('/assets/svg/pv-lock.svg');
  }
  &.pv-key {
    background-image: url('/assets/svg/pv-key.svg');
  }
  &.pv-medal {
    background-image: url('/assets/svg/pv-medal.svg');
  }
  &.pv-file-search {
    background-image: url('/assets/svg/pv-file-search.svg');
  }
  &.pv-doc-history {
    background-image: url('/assets/svg/pv-doc-history.svg');
  }
  &.pv-file-contract {
    background-image: url('/assets/svg/pv-file-contract.svg');
  }
  &.pv-user-decision {
    background-image: url('/assets/svg/pv-user-decision.svg');
  }
  &.pv-chat {
    background-image: url('/assets/svg/pv-chat.svg');
  }
  &.pv-newspaper {
    background-image: url('/assets/svg/pv-newspaper.svg');
  }
  &.pv-employee {
    background-image: url('/assets/svg/pv-employee.svg');
  }
  &.pv-truck-service {
    background-image: url('/assets/svg/pv-truck-service.svg');
  }
  &.pv-file-circle-check-alt {
    background-image: url('/assets/svg/pv-file-circle-check-alt.svg');
  }
  &.pv-bullhorn-alt {
    background-image: url('/assets/svg/pv-bullhorn-alt.svg');
  }
  &.pv-bell-alt {
    background-image: url('/assets/svg/pv-bell-alt.svg');
  }
  &.pv-clock {
    background-image: url('/assets/svg/pv-clock.svg');
  }
  &.pv-handshake {
    background-image: url('/assets/svg/pv-handshake.svg');
  }
  &.pv-stopwatch {
    background-image: url('/assets/svg/pv-stopwatch.svg');
  }
  &.pv-connection-triangle {
    background-image: url('/assets/svg/pv-connection-triangle.svg');
  }
  &.pv-clock-times {
    background-image: url('/assets/svg/pv-clock-times.svg');
  }
  &.pv-sign-out {
    background-image: url('/assets/svg/pv-sign-out.svg');
  }
  &.pv-social {
    background-image: url('/assets/svg/pv-social.svg');
  }
  &.pv-shopping-cart-circle-check {
    background-image: url('/assets/svg/pv-shopping-cart-circle-check.svg');
  }
  &.pv-box-envelope {
    background-image: url('/assets/svg/pv-box-envelope.svg');
  }
  &.pv-auction {
    background-image: url('/assets/svg/pv-auction.svg');
  }
  &.pv-statistics {
    background-image: url('/assets/svg/pv-statistics.svg');
  }
  &.pv-truck-delivery {
    background-image: url('/assets/svg/pv-truck-delivery.svg');
  }
  &.pv-note {
    background-image: url('/assets/svg/pv-note.svg');
  }
  &.pv-saving {
    background-image: url('/assets/svg/pv-saving.svg');
  }
  &.pv-speedometer {
    background-image: url('/assets/svg/pv-speedometer.svg');
  }

  &.pv-doughnut-chart-dollar-sign {
    background-image: url('/assets/svg/pv-doughnut-chart-dollar-sign.svg');
  }

  &.pv-pie-chart {
    background-image: url('/assets/svg/pv-pie-chart.svg');
  }

  &.pv-bar-chart-efficiency {
    background-image: url('/assets/svg/pv-bar-chart-efficiency.svg');
  }

  &.pv-money-bag {
    background-image: url('/assets/svg/pv-money-bag.svg');
  }

  &.pv-hand-times-circle {
    background-image: url('/assets/svg/pv-hand-times-circle.svg');
  }

  &.pv-star-circle {
    background-image: url('/assets/svg/pv-star-circle.svg');
  }

  &.pv-box-delivery {
    background-image: url('/assets/svg/pv-box-delivery.svg');
  }

  &.pv-calendar-redo {
    background-image: url('/assets/svg/pv-calendar-redo.svg');
  }

  &.pv-graphic-board {
    background-image: url('/assets/svg/pv-graphic-board.svg');
  }

  &.pv-calendar {
    background-image: url('/assets/svg/pv-calendar.svg');
  }
  &.pv-trash {
    background-image: url('/assets/svg/pv-trash.svg');
  }
  &.pv-purchase {
    background-image: url('/assets/svg/pv-purchase.svg');
  }
  &.pv-proposal {
    background-image: url('/assets/svg/pv-proposal.svg');
  }
  &.pv-quotation {
    background-image: url('/assets/svg/pv-quotation.svg');
  }
  &.pv-order-process {
    background-image: url('/assets/svg/pv-order-process.svg');
  }
  &.pv-calculator {
    background-image: url('/assets/svg/pv-calculator.svg');
  }
  &.pv-transparency {
    background-image: url('/assets/svg/pv-transparency.svg');
  }
  &.pv-invoice {
    background-image: url('/assets/svg/pv-invoice.svg');
  }
  &.pv-stakeholder {
    background-image: url('/assets/svg/pv-stakeholder.svg');
  }
  &.pv-bulk {
    background-image: url('/assets/svg/pv-bulk.svg');
  }
  &.pv-binder {
    background-image: url('/assets/svg/pv-binder.svg');
  }
  &.pv-clipboard-x {
    background-image: url('/assets/svg/pv-clipboard-x.svg');
  }
  &.pv-shield-red {
    background-image: url('/assets/svg/pv-shield-red.svg');
  }
  &.pv-clipboard-x-red {
    background-image: url('/assets/svg/pv-clipboard-x-red.svg');
  }
}

.pvr {
  &.pv-user-circle {
    background-image: url('/assets/svg/pv-user-circle-regular.svg');
  }
  &.pv-box {
    background-image: url('/assets/svg/pv-box-regular.svg');
  }

  &.pv-tools {
    background-image: url('/assets/svg/pv-tools-regular.svg');
  }

  &.pv-cloud-upload {
    background-image: url('/assets/svg/pv-cloud-upload-regular.svg');
  }
}

.pvf {
  &.pv-id {
    background-image: url('/assets/svg/pv-id-flag.svg');
  }

  &.pv-uk {
    background-image: url('/assets/svg/pv-uk-flag.svg');
  }
}
