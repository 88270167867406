$basePath: '../assets/fonts/icon/';
$promiseIconFontFamily: 'promiseicon';
$promiseSVGSize: 18px;
$piBorder: 1px solid var(--gray-500);
$piSize: 0.875rem;
@font-face {
  font-family: $promiseIconFontFamily;
  src: url($basePath + 'promiseicon.eot?ufmisq');
  src: url($basePath + 'promiseicon.eot?ufmisq#iefix')
      format('embedded-opentype'),
    url($basePath + 'promiseicon.ttf?ufmisq') format('truetype'),
    url($basePath + 'promiseicon.woff?ufmisq') format('woff'),
    url($basePath + 'promiseicon.svg?ufmisq#promiseicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='pi-'],
[class*=' pi-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: $promiseIconFontFamily !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.pi-border {
  border: $piBorder;
  display: inline-flex;
  width: $piSize + 0.25;
  height: $piSize + 0.25;
  align-items: center;
  justify-content: center;
}

.pi-rounded {
  border-radius: 50%;
  padding: 4px;
}

$fontSizeList: 1rem, 1.125rem, 1.5rem, 2.25rem;
@for $i from 1 through 4 {
  .pi-#{$i}x {
    $_fontSize: nth($fontSizeList, $i);
    font-size: $_fontSize;
    &.pi-border {
      width: $_fontSize + 0.25;
      height: $_fontSize + 0.25;
    }
  }
}

$pirPencilAlt: '\e900';
$pirShoppingCart: '\e901';
$pirChatBox: '\e902';
$pirArrowDown: '\e903';
$pirChevronDown: '\e904';
$pirUser: '\e905';
$pirNumberOneCircle: '\e906';
$pirPlus: '\e907';
$pirTimesCircle: '\e908';
$pirSortDescending: '\e909';
$pirMapMarkerAlt: '\e90a';
$pirClipboard: '\e90b';
$pirBox: '\e90c';
$pirFilter: '\e90d';
$pirArrowUp: '\e90e';
$pirChevronUp: '\e90f';
$pirUserPlus: '\e910';
$pirNumberTwoCircle: '\e911';
$pirCalendar: '\e912';
$pirWindowsMazimize: '\e913';
$pirSortAscending: '\e914';
$pirCheckDouble: '\e915';
$pirHeart: '\e916';
$pirDownloadLine: '\e917';
$pirBookmark: '\e918';
$pirArrowRight: '\e919';
$pirChevronRight: '\e91a';
$pirUserCog: '\e91b';
$pirNumberThreeCircle: '\e91c';
$pirSearch: '\e91d';
$pirBars: '\e91e';
$pirDotCircle: '\e91f';
$pirQuestionCircle: '\e920';
$pirCheck: '\e921';
$pirEye: '\e922';
$pirEllipsisV: '\e923';
$pirArrowLeft: '\e924';
$pirChevronLeft: '\e925';
$pirUserFriends: '\e926';
$pirNumberFourCircle: '\e927';
$pirStore: '\e928';
$pirHalfView: '\e929';
$pirClockAlarm: '\e92a';
$pirCircle: '\e92b';
$pirTimes: '\e92c';
$pirTrashAlt: '\e92d';
$pirRedo: '\e92e';
$pirCommentsDot: '\e92f';
$pirPaperclip: '\e930';
$pirNumberFiveCircle: '\e931';
$pirColumn: '\e932';
$pirRow: '\e933';
$pisInfoCircle: '\e934';
$pirCurrencyNameIdr: '\e935';
$pirBorderAlt: '\e936';
$pirChartBarV: '\e937';
$pirCheckSquare: '\e938';
$pirCompare: '\e939';
$pirChevronCircleUp: '\e93a';
$pirChevronCircleDown: '\e93b';
$pirChevronCircleLeft: '\e93c';
$pirChevronCircleRight: '\e93d';
$pirFile: '\e93e';
$pirImage: '\e93f';
$pirExternalLink: '\e940';
$pirPaperPlane: '\e941';
$pirMassKg: '\e942';
$pirArrowRightStraight: '\e943';
$pirArrowLeftBack: '\e944';
$pirDashboard: '\e945';
$pirReceipt: '\e946';
$pirBookmarkAlt: '\e947';
$pirChartBarH: '\e948';
$pirHistory: '\e949';
$pirItalic: '\e94a';
$pirListUl: '\e94b';
$pirStrikethrough: '\e94c';
$pirListOl: '\e94d';
$pirLink: '\e94e';
$pirBold: '\e94f';
$pirUnderline: '\e950';
$pirHandshake: '\e951';
$pirThumbs: '\e952';
$pirTag: '\e953';
$pirClock: '\e954';
$pirEllipsisVAlt: '\e955';
$pirEnvelope: '\e956';
$pirPhone: '\e957';
$pirSignOut: '\e958';
$pirSmile: '\e959';
$pirEquals: '\e95a';
$pirEyeSlash: '\e95b';
$pirInfoCircle: '\e95c';
$picTrophy: '\e95d';
$picStar: '\e95e';
$pisExcalmationTriangle: '\e95f';
$pirBan: '\e960';
$pirBell: '\e962';
$pirCarbonBox: '\e963';
$pirCheckCircle: '\e964';
$picClockCircleBefore: '\e965';
$picClockCircleAfter: '\e966';
$pirEdit: '\e967';
$pirEraser: '\e968';
$pirFileSearch: '\e969';
$pirHourglass: '\e96a';
$picPencilCircleBefore: '\e96b';
$picPencilCircleAfter: '\e96c';
$pirPlusCircle: '\e96d';
$picThumbsUpCircleBefore: '\e96e';
$picThumbsUpCircleAfter: '\e96f';
$pirGlobe: '\e970';
$pirQRCode: '\e971';
$picTimesCircleBefore: '\e972';
$picTimesCircleAfter: '\e973';
$pirPrinter: '\e974';
$pirWindowmaximize: '\e975';
$pirMinus: '\e976';
$picWarningFilled: '\e977';
$pirDollarSignCircle: '\e97d';
$pirFilesAlt: '\e97e';
$pirCog: '\e97f';
$pirFolder: '\e980';
$pirAirplay: '\e981';
$pirExpandArrowsAlt: '\e982';
$pirHandWaving: '\e983';
$pirFileDownload: '\e984';
$pirEditAlt: '\e985';
$pirFileListing: '\e986';
$pirCarbonMeter: '\e987';
$pirCompressAlt: '\e988';
$pirExpandAlt: '\e989';
$pirBullhornAlt: '\e98a';
$pirHandRock: '\e98b';
$pirBuildingHouse: '\e98c';
$pirUserArrowUpRight: '\e98d';
$pirWallet: '\e98e';
$pirColorPicker: '\e98f';
$pirSitemap: '\e990';

.pir {
  &.pi-pencil-alt::before {
    content: $pirPencilAlt;
  }
  &.pi-shopping-cart::before {
    content: $pirShoppingCart;
  }
  &.pi-chat-box::before {
    content: $pirChatBox;
  }
  &.pi-arrow-down::before {
    content: $pirArrowDown;
  }
  &.pi-chevron-down::before {
    content: $pirChevronDown;
  }
  &.pi-user::before {
    content: $pirUser;
  }

  &.pi-number-one-circle::before {
    content: $pirNumberOneCircle;
  }
  &.pi-plus::before {
    content: $pirPlus;
  }
  &.pi-times-circle::before {
    content: $pirTimesCircle;
  }
  &.pi-sort-descending::before {
    content: $pirSortDescending;
  }
  &.pi-map-marker-alt::before {
    content: $pirMapMarkerAlt;
  }
  &.pi-clipboard::before {
    content: $pirClipboard;
  }
  &.pi-box::before {
    content: $pirBox;
  }
  &.pi-filter::before {
    content: $pirFilter;
  }
  &.pi-arrow-up::before {
    content: $pirArrowUp;
  }
  &.pi-chevron-up::before {
    content: $pirChevronUp;
  }
  &.pi-user-plus::before {
    content: $pirUserPlus;
  }
  &.pi-number-two-circle::before {
    content: $pirNumberTwoCircle;
  }
  &.pi-calendar::before {
    content: $pirCalendar;
  }
  &.pi-windows-maximize::before {
    content: $pirWindowsMazimize;
  }
  &.pi-sort-ascending::before {
    content: $pirSortAscending;
  }
  &.pi-check-double::before {
    content: $pirCheckDouble;
  }
  &.pi-heart::before {
    content: $pirHeart;
  }
  &.pi-download-line::before {
    content: $pirDownloadLine;
  }
  &.pi-bookmark::before {
    content: $pirBookmark;
  }
  &.pi-arrow-right::before {
    content: $pirArrowRight;
  }
  &.pi-chevron-right::before {
    content: $pirChevronRight;
  }
  &.pi-user-cog::before {
    content: $pirUserCog;
  }
  &.pi-number-three-circle::before {
    content: $pirNumberThreeCircle;
  }
  &.pi-search::before {
    content: $pirSearch;
  }
  &.pi-bars::before {
    content: $pirBars;
  }
  &.pi-dot-circle::before {
    content: $pirDotCircle;
  }
  &.pi-question-circle::before {
    content: $pirQuestionCircle;
  }
  &.pi-check::before {
    content: $pirCheck;
  }
  &.pi-eye::before {
    content: $pirEye;
  }

  &.pi-ellipsis-v::before {
    content: $pirEllipsisV;
  }

  &.pi-arrow-left::before {
    content: $pirArrowLeft;
  }
  &.pi-chevron-left::before {
    content: $pirChevronLeft;
  }
  &.pi-user-friends::before {
    content: $pirUserFriends;
  }
  &.pi-number-four-circle::before {
    content: $pirNumberFourCircle;
  }
  &.pi-store::before {
    content: $pirStore;
  }
  &.pi-half-view::before {
    content: $pirHalfView;
  }
  &.pi-clock-alarm::before {
    content: $pirClockAlarm;
  }
  &.pi-circle::before {
    content: $pirCircle;
  }
  &.pi-times::before {
    content: $pirTimes;
  }
  &.pi-trash-alt::before {
    content: $pirTrashAlt;
  }
  &.pi-redo::before {
    content: $pirRedo;
  }
  &.pi-comments-dot::before {
    content: $pirCommentsDot;
  }
  &.pi-paperclip::before {
    content: $pirPaperclip;
  }
  &.pi-number-five-circle::before {
    content: $pirNumberFiveCircle;
  }
  &.pi-column::before {
    content: $pirColumn;
  }
  &.pi-row::before {
    content: $pirRow;
  }
  &.pi-info-circle::before {
    content: $pirInfoCircle;
  }
  &.pi-currency-name-idr::before {
    content: $pirCurrencyNameIdr;
  }
  &.pi-border-alt::before {
    content: $pirBorderAlt;
  }
  &.pi-chart-bar-v::before {
    content: $pirChartBarV;
  }
  &.pi-check-square::before {
    content: $pirCheckSquare;
  }
  &.pi-compare::before {
    content: $pirCompare;
  }
  &.pi-chevron-circle-up::before {
    content: $pirChevronCircleUp;
  }
  &.pi-chevron-circle-down::before {
    content: $pirChevronCircleDown;
  }
  &.pi-chevron-circle-left::before {
    content: $pirChevronCircleLeft;
  }
  &.pi-chevron-circle-right::before {
    content: $pirChevronCircleRight;
  }
  &.pi-image::before {
    content: $pirImage;
  }
  &.pi-external-link::before {
    content: $pirExternalLink;
  }
  &.pi-paper-plane::before {
    content: $pirPaperPlane;
  }
  &.pi-mass-kg::before {
    content: $pirMassKg;
  }
  &.pi-arrow-right-stright::before {
    content: $pirArrowRightStraight;
  }
  &.pi-arrow-left-back::before {
    content: $pirArrowLeftBack;
  }
  &.pi-dashboard::before {
    content: $pirDashboard;
  }
  &.pi-receipt::before {
    content: $pirReceipt;
  }
  &.pi-bookmark-alt::before {
    content: $pirBookmarkAlt;
  }
  &.pi-chart-bar-h::before {
    content: $pirChartBarH;
  }
  &.pi-history::before {
    content: $pirHistory;
  }
  &.pi-italic::before {
    content: $pirItalic;
  }
  &.pi-list-ul::before {
    content: $pirListUl;
  }
  &.pi-strikethrough::before {
    content: $pirStrikethrough;
  }
  &.pi-list-ol::before {
    content: $pirListOl;
  }
  &.pi-link::before {
    content: $pirLink;
  }
  &.pi-bold::before {
    content: $pirBold;
  }
  &.pi-underline::before {
    content: $pirUnderline;
  }

  &.pi-handshake::before {
    content: $pirHandshake;
  }
  &.pi-thumbs-up::before {
    content: $pirThumbs;
  }
  &.pi-tag::before {
    content: $pirTag;
  }
  &.pi-clock::before {
    content: $pirClock;
  }
  &.pi-elipsis-v::before {
    content: $pirEllipsisV;
  }
  &.pi-envelope::before {
    content: $pirEnvelope;
  }
  &.pi-phone::before {
    content: $pirPhone;
  }
  &.pi-sign-out::before {
    content: $pirSignOut;
  }
  &.pi-smile::before {
    content: $pirSmile;
  }
  &.pi-equals::before {
    content: $pirEquals;
  }
  &.pi-eye-slash::before {
    content: $pirEyeSlash;
  }
  &.pi-file-alt::before {
    content: $pirFile;
  }
  &.pi-ban::before {
    content: $pirBan;
  }
  &.pi-bell::before {
    content: $pirBell;
  }
  &.pi-carbon-box::before {
    content: $pirCarbonBox;
  }
  &.pi-check-circle::before {
    content: $pirCheckCircle;
  }
  &.pi-edit::before {
    content: $pirEdit;
  }
  &.pi-eraser::before {
    content: $pirEraser;
  }
  &.pi-file-search::before {
    content: $pirFileSearch;
  }
  &.pi-hourglass::before {
    content: $pirHourglass;
  }
  &.pi-plus-circle::before {
    content: $pirPlusCircle;
  }
  &.pi-globe::before {
    content: $pirGlobe;
  }
  &.pi-qr-code::before {
    content: $pirQRCode;
  }
  &.pi-printer::before {
    content: $pirPrinter;
  }
  &.pi-window-maximize::before {
    content: $pirWindowmaximize;
  }
  &.pi-minus::before {
    content: $pirMinus;
  }
  &.pi-dollar-sign-circle::before {
    content: $pirDollarSignCircle;
  }
  &.pi-files-alt::before {
    content: $pirFilesAlt;
  }
  &.pi-cog::before {
    content: $pirCog;
  }
  &.pi-folder::before {
    content: $pirFolder;
  }
  &.pi-airplay::before {
    content: $pirAirplay;
  }
  &.pi-expand-arrows-alt::before {
    content: $pirExpandArrowsAlt;
  }
  &.pi-hand-waving::before {
    content: $pirHandWaving;
  }
  &.pi-file-download::before {
    content: $pirFileDownload;
  }
  &.pi-edit-alt::before {
    content: $pirEditAlt;
  }
  &.pi-file-listing::before {
    content: $pirFileListing;
  }
  &.pi-carbon-meter::before {
    content: $pirCarbonMeter;
  }
  &.pi-compress-alt::before {
    content: $pirCompressAlt;
  }
  &.pi-expand-alt::before {
    content: $pirExpandAlt;
  }
  &.pi-bullhorn-alt::before {
    content: $pirBullhornAlt;
  }
  &.pi-hand-rock::before {
    content: $pirHandRock;
  }
  &.pi-building-house::before {
    content: $pirBuildingHouse;
  }
  &.pi-user-arrow-top-right::before {
    content: $pirUserArrowUpRight;
  }
  &.pi-wallet::before {
    content: $pirWallet;
  }
  &.pi-color-picker::before {
    content: $pirColorPicker;
  }
  &.pi-sitemap::before {
    content: $pirSitemap;
  }
}

.pis {
  &.pi-info-circle::before {
    content: $pisInfoCircle;
  }
  &.pi-exclamation-triangle::before {
    content: $pisExcalmationTriangle;
  }
  &.pi-circle::before {
    content: '\e98d';
  }
}

.pib {
  &.pi-google::before {
    content: '';
    background-image: url('../assets/svg/pi-google.svg');
    display: inline-block;
    background-size: $promiseSVGSize $promiseSVGSize;
    width: $promiseSVGSize;
    height: $promiseSVGSize;
  }
}

.pic {
  &.pi-trophy::before {
    content: $picTrophy;
    color: #ffd84d;
  }
  &.pi-star::before {
    content: $picStar;
    color: #ffd84d;
  }

  &.pi-exclamation-triangle {
    content: '';
    background-image: url('../assets/svg/pi-exclamation-triangle.svg');
    display: inline-block;
    background-size: $promiseSVGSize $promiseSVGSize;
    width: $promiseSVGSize;
    height: $promiseSVGSize;
  }
  &.pi-clock-circle::before {
    content: $picClockCircleBefore;
    color: rgb(255, 204, 41);
  }
  &.pi-clock-circle::after {
    content: $picClockCircleAfter;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.pi-pencil-circle::before {
    content: $picPencilCircleBefore;
    color: rgb(7, 114, 182);
  }
  &.pi-pencil-circle::after {
    content: $picPencilCircleAfter;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.pi-thumbs-up-circle::before {
    content: $picThumbsUpCircleBefore;
    color: rgb(40, 180, 70);
  }
  &.pi-thumbs-up-circle::after {
    content: $picThumbsUpCircleAfter;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
  &.pi-times-circle::before {
    content: $picTimesCircleBefore;
    color: rgb(255, 63, 78);
  }
  &.pi-times-circle::after {
    content: $picTimesCircleAfter;
    margin-left: -1em;
    color: rgb(255, 255, 255);
  }
}

// custom icons
.pis.pi-sort {
  font-family: $promiseIconFontFamily !important;
  display: inline-block;
  color: var(--colorOnTableHeaderColor);
  font-weight: bold;
  cursor: pointer;
  font-style: normal;
  text-align: center;
  font-size: 12px;
  &::before,
  &::after {
    content: $pirChevronUp;
    width: 100%;
    float: left;
    padding: 0;
    margin: 0;
    line-height: 7px;
  }

  &::before {
    content: $pirChevronUp;
  }

  &::after {
    content: $pirChevronDown;
  }

  &.sort-asc {
    &::after {
      color: var(--gray-500);
    }
  }
  &.sort-desc {
    &::before {
      color: var(--gray-500);
    }
  }
}
