// bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

@import 'fonts';
@import 'colors';
@import 'variables';
@import 'variable-override';
@import 'mixins';
@import 'root';
@import 'reboot';
@import 'loading';
@import 'type';
@import 'icon';
@import '~bootstrap/scss/bootstrap';
@import 'components';
@import 'widgets';
@import 'customs';
@import 'overrides';
@import 'utilities';
@import 'vector';

.max-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.promise-container {
  display: block;
  width: 100%;
  padding: 20px 40px;
  .card {
    margin: 0 auto;
  }
  &.promise-container-sm {
    .card {
      max-width: 576px;
      padding: 40px;
      margin: 0 auto;
    }
  }
  &.promise-container-lg {
    .card {
      max-width: 1024px;
      padding: 40px 32px;
      margin: 0 auto;
    }
  }
  .promise-container-left {
    width: calc(100% - 400px - 40px);
    display: inline-block;
  }
  .promise-container-right {
    width: 400px;
    display: inline-block;
    margin-left: 40px;
    &.sticky {
      position: sticky;
      top: 98px;
    }
  }
}

.btn.btn-primary {
  background: var(--primaryColor);
  color: var(--colorOnPrimaryColor);
  border-color: var(--primaryColor) !important;
  &:hover,
  &:focus,
  &:active,
  &:visited {
    background: var(--primaryColorDarker) !important;
    color: var(--colorOnPrimaryColor) !important;
    border-color: var(--primaryColorDarker) !important;
  }
}

@include mediaScreen(768px) {
  .promise-container {
    &.promise-container-lg {
      width: 100%;
    }
  }
}
